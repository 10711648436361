import SearchBar from './SearchBar'
import { Link } from "react-router-dom";
import React, { useState } from 'react';

function Header() {
  const [activeWindow, setActiveWindow] = useState('home');

  // Funkce pro změnu aktuálního okna
  const setActive = (window) => {
    setActiveWindow(window);
  };

  return (
    <div className="headerDiv">
        <div className="titleDiv">
        </div>
        <nav className="navBar">
           <Link to="/" className={activeWindow === 'home' ? 'active' : 'navButton'} onClick={() => setActive('home')}>MemeFlix</Link>
          <Link to="/filmy" className={activeWindow === 'filmy' ? 'active' : 'navButton'} onClick={() => setActive('filmy')}>Filmy</Link>
          <Link to="/memeflix-origins" className={activeWindow === 'memeflix-origins' ? 'active' : 'navButton'} onClick={() => setActive('memeflix-origins')}>MemeFlix Origins</Link>
          <Link to="/herci" className={activeWindow === 'herci' ? 'active' : 'navButton'} onClick={() => setActive('herci')}>Herci</Link>
          <Link to="/videa" className={activeWindow === 'videa' ? 'active' : 'navButton'} onClick={() => setActive('videa')}>Videa</Link>
          <Link to="/about" className={activeWindow === 'about' ? 'active' : 'navButton'} onClick={() => setActive('about')}>O nás</Link>
          <SearchBar className='navButton'/>
        </nav>
    </div>

  );
}

export default Header;