import React, { useEffect, useState } from "react";

export function Herci() {
  const [actorDataList, setActorDataList] = useState([]);


  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://memeflix.tadeascerny.cz/php/actors.php"
      );
      const data = await response.json();
      // Sort the actors by name alphabetically
      const sortedData = data.sort((a, b) => a.a_name.localeCompare(b.a_name));
      setActorDataList(sortedData);
    }
    getData();
  }, []);
  

  
  return (
    <div className="reviewDiv">
      <h1 className="fade-in">Herci</h1>
      {actorDataList.map((actorData) => (
        <ActorData key={actorData.id} actorData={actorData} />
        ))}
    </div>
  );
}
function ActorData({ actorData }) {
  const [showFullContent, setShowFullContent] = useState(false);

  const handleVice = () => {
    setShowFullContent(!showFullContent);

  };

  const handleClick = () => {
    // Přesměrování na stránku s konkrétním filmem
    window.location.href = `/herec/${actorData.a_url}`;
  
  };

  return (
      <div className="herciDiv" key={actorData.id} onClick={handleClick}>
        <div className="hercidivos">
        <h4 className="jmeno">{actorData.a_name} {actorData.a_family_name}</h4>
        <img className="fade-in" src={actorData.a_img} alt={actorData.a_name} />
        <p className="her" onClick={handleVice}>
          {showFullContent ? (
            actorData.a_bio
          ) : (
            <>
              {`${actorData.a_bio.slice(0, 100)}... `}
              <span className="more-info">pro více informací klikněte ZDE</span>
            </>
          )}
        </p>
        </div>
      </div>
    );
  }
  
  