import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { Filmy } from "./pages/Filmy";
import { Film } from "./pages/Film";
import { Herci } from "./pages/Herci";
import { Herec } from "./pages/Herec";
import { MemeflixOrigin } from "./pages/Memeflix-origin";
import { MemeflixOrigins } from "./pages/Memeflix-origins";
import { Videa } from "./pages/Videa";
import { About } from "./pages/About";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/filmy" element={<Filmy />} />
      <Route path="/film/:film_url" element={<Film />} />
      <Route path="/herci" element={<Herci />} />
      <Route path="/herec/:a_url" element={<Herec />} />
      <Route path="/memeflix-origins" element={<MemeflixOrigins />} />
      <Route path="/memeflix-origin/:m_film_url" element={<MemeflixOrigin />} />
      <Route path="/videa" element={<Videa />} />
      <Route path="/about" element={<About />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
