import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NotFound } from "./NotFound";

export function Herec() {
  const { a_url } = useParams();
  const [actorData, setActorData] = useState(null);
  const { filmID } = useParams();
  const [FilmyData, setFilmyData] = useState(null);
  const [showFullContent, setShowFullContent] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleClick = () => {
    setShowFullContent(!showFullContent);
  };

  const toggleFullscreen = (e) => {
    if (isFullscreen) {
      shrinkFullscreenImage(e.target);
    } else {
      setIsFullscreen(true);
    }
  };

  const shrinkFullscreenImage = (target) => {
    target.style.animation =
      "slide-out-blurred-top 0.5s cubic-bezier(0.755, 0.050, 0.855, 0.060)";
    setTimeout(function () {
      setIsFullscreen(false);
      target.style.animation = "restart 0.2s";
    }, 500);
  };

  useEffect(() => {
    async function getActorData() {
    
      const response = await fetch(
        `https://memeflix.tadeascerny.cz/php/actors.php`
      );
      const data = await response.json();
     

      // Find the actor with the matching a_url
      const foundActor = data.find((actor) => actor.a_url === a_url);
      setActorData(foundActor);

      // Update the page title
      document.title = "MemeFlix - "+foundActor.a_name+" "+foundActor.a_family_name || "Actor Detail";
    }

    getActorData();
  }, [a_url]);

  useEffect(() => {
    async function getActorsData() {
   

      const response = await fetch(
        `https://memeflix.tadeascerny.cz/php/filmy_show.php`,
        {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ a_url: a_url }),
        }
      );
      const fdata = await response.json();
   
      setFilmyData(fdata);
    }

    getActorsData();
  }, [filmID, a_url]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isFullscreen && !e.target.classList.contains("base")) {
        const imageElement = document.querySelector(".base");
        if (imageElement) {
          shrinkFullscreenImage(imageElement);
        }
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Escape") {
        const imageElement = document.querySelector(".base");
        if (imageElement) {
          shrinkFullscreenImage(imageElement);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isFullscreen]);

      // useEffect pro nastavení původního názvu stránky při opuštění detailu filmu
      useEffect(() => {
        return () => {
          document.title = "MemeFlix"; // Nahraďte skutečným původním názvem stránky
        };
      }, []);

  if (!actorData) {
    return <NotFound/>;
  }

  if (!FilmyData) {
    return <NotFound/>;
  }
  const handleAClick = (url) => {
    // Redirect to the actor's page using the a_url parameter
    window.location.href = `/film/${url}`;
  };
  return (
    <div className="reviewDiv">
      <div className="faktnevim">
      <h3 className="birth">{actorData.a_birthdate}</h3>
        <h1 className="text1">
          {actorData.a_name} {actorData.a_family_name}
        </h1>
        <img
          src={actorData.a_img}
          alt={actorData.a_url}
          onClick={toggleFullscreen}
          className={isFullscreen ? "fullscreen-image base" : "base"}></img>
        <p className="text2" onClick={handleClick}>
          {showFullContent ? (
            actorData.a_bio
          ) : (
            <>
              {`${actorData.a_bio.slice(0, 300)}... `}
              <span className="more-info">pro více informací klikněte ZDE</span>
            </>
          )}
        </p>
      </div>
      <h3 className="birth">Filmy </h3>
        <ol className="birth2">
          {FilmyData.map((filmy) => (
            <li key={filmy.url} className="FAseznam" onClick={() => handleAClick(filmy.url)}>{filmy.title}</li>
          ))}
        </ol>
    </div>
  );
}