import React, { useEffect, useState } from "react";

export function Home() {
  const [homeDataList, setHomeDataList] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://memeflix.tadeascerny.cz/php/filmy.php"
      );
      const data = await response.json();
      const filteredData = data.filter((homeData) => homeData.rating > 80);
  
      // Sort the filteredData array by score (rating) in descending order
      filteredData.sort((a, b) => b.rating - a.rating);
  
      setHomeDataList(filteredData);
    }
    getData();
  }, []);
  

  return (
    <div className="reviewDiv">
      <h1 className="fade-in">Top filmy</h1>
      {homeDataList.map((homeData) => (
        <HomeData key={homeData.id} homeData={homeData} />
      ))}
    </div>
  );
}


function HomeData({ homeData }) {
  const [showFullContent] = useState(false);


  const handleClick = () => {
    // Přesměrování na stránku s konkrétním filmem
    window.location.href = `/film/${homeData.film_url}`;
 
  };


return (
    <div className="filmyDiv" key={homeData.id} onClick={handleClick}>
      <h2 className="fade-in">{homeData.title}</h2>
      <div className="homepage">
      <div className="filminfo">
      <img className="fade-in" src={homeData.film_img} alt={homeData.title} />
      <button className="rating">{homeData.rating}%</button>
      </div>
      <h5 className="nevimuz">
      {showFullContent ? (
            homeData.film_content
          ) : (
            <>
              {`${homeData.film_content.slice(0, 500)}... `}
              <span className="more-info">pro více informací klikněte ZDE</span>
            </>
          )}</h5>
      </div>
    </div>
  );
}