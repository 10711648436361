import React from "react";
import styles from "../css/NotFound.module.css";

export function NotFound() {
  return (
    <div className={styles.notFound}>
      <h1 className={styles.heading}>Jejda!</h1>
      <p className={styles.paragraph}>
        Je nám líto, ale tento odkaz neexistuje.
      </p>
      <div className={styles.loader}></div>
    </div>
  );
}
