import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NotFound } from "./NotFound";

export function Film() {
  const { film_url } = useParams();
  const [filmData, setFilmData] = useState(null);
  const [showFullContent, setShowFullContent] = useState(false);
  const { actorId } = useParams();
  const [ActorsData, setActorsData] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleClick = () => {
    setShowFullContent(!showFullContent);
  };

  const toggleFullscreen = (e) => {
    if (isFullscreen) {
      shrinkFullscreenImage(e.target);
    } else {
      setIsFullscreen(true);
    }
  };

  const shrinkFullscreenImage = (target) => {
    target.style.animation =
      "slide-out-blurred-top 0.5s cubic-bezier(0.755, 0.050, 0.855, 0.060)";
    setTimeout(function () {
      setIsFullscreen(false);
      target.style.animation = "restart 0.2s";
    }, 500);
  };

  useEffect(() => {
    async function getFilmData() {
      const response = await fetch(
        `https://memeflix.tadeascerny.cz/php/filmy.php`
      );
      const data = await response.json();

      // Find the film with the matching film_url
      const foundFilm = data.find((film) => film.film_url === film_url);
      setFilmData(foundFilm);

      // Update the page title
      document.title = "MemeFlix - "+foundFilm.title || "Film Detail";
    }

    getFilmData();
  }, [film_url]);

  useEffect(() => {
    async function getActorsData() {
      const response = await fetch(
        `https://memeflix.tadeascerny.cz/php/actors_show.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ film_url: film_url }),
        }
      );
      const adata = await response.json();
      setActorsData(adata);
    }

    getActorsData();
  }, [actorId, film_url]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isFullscreen && !e.target.classList.contains("base")) {
        const imageElement = document.querySelector(".base");
        if (imageElement) {
          shrinkFullscreenImage(imageElement);
        }
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Escape") {
        const imageElement = document.querySelector(".base");
        if (imageElement) {
          shrinkFullscreenImage(imageElement);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isFullscreen]);

    // useEffect pro nastavení původního názvu stránky při opuštění detailu filmu
  useEffect(() => {
    return () => {
      document.title = "MemeFlix"; // Nahraďte skutečným původním názvem stránky
    };
  }, []);

  if (!filmData || !ActorsData) {
    return <NotFound />;
  }

  const handleAClick = (url) => {
    // Redirect to the actor's page using the a_url parameter
    window.location.href = `/herec/${url}`;
  };

  return (
    <div className="reviewDiv">
      <h1 className="fade-in">{filmData.title}</h1>
      <div className="filmydiv">
        <div className="filminfo">
          <h3 className="filmdisc1">
            {filmData.full_name.length > 25
              ? `${filmData.full_name.slice(0, 25)}...`
              : filmData.full_name}
          </h3>
          <img
            src={filmData.film_img}
            alt={filmData.title}
            onClick={toggleFullscreen}
            className={isFullscreen ? "fullscreen-image base" : "base"}
          />
        </div>
        <div className="videof">
          <iframe
            className="video"
            title="Youtube player"
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={`https://youtube.com/embed/${filmData.youtubeID}?autoplay=0`}
          />
        </div>
        <p className="rating2">{filmData.rating} %</p>
        <p className="text123" onClick={handleClick}>
          {showFullContent ? (
            filmData.film_content
          ) : (
            <>
              {`${filmData.film_content.slice(0, 300)}...`}
              <span className="more-info">pro více informací klikněte ZDE</span>
            </>
          )}
        </p>
        <h4>Hrají: </h4>
        <ul>
          {ActorsData.map((actor) => (
            <li key={actor.id} className="FAseznam" onClick={() => handleAClick(actor.url)}>
              {actor.name} {actor.family_name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}