import React, { useEffect, useState } from "react";

export function Filmy() {
  const [filmDataList, setFilmDataList] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://memeflix.tadeascerny.cz/php/filmy.php"
      );
      const data = await response.json();
      // Sort films alphabetically by title
      const sortedData = data.sort((a, b) => a.title.localeCompare(b.title));
      setFilmDataList(sortedData);
    }
    getData();
  }, []);

  return (
    <div className="reviewDiv">
      <h1 className="fade-in">Všechny filmy A-Z</h1>
      <div className="filmos">
      {filmDataList.map((filmData) => (
        <FilmData key={filmData.id} filmData={filmData} />
      ))}
      </div>
    </div>
  );
}

function FilmData({ filmData }) {


  const handleClick = () => {
    // Přesměrování na stránku s konkrétním filmem
    window.location.href = `/film/${filmData.film_url}`;

  };

  return (
    <div className="filmyDiv"key={filmData.id} onClick={handleClick}>
      <div className="filminfo">
      <h3 className="filmdisc">
        {
          (filmData.full_name.length > 25) ? `${filmData.full_name.slice(0, 25)}... ` : filmData.full_name 
        }
        </h3>
      <img className="fade-in" src={filmData.film_img} alt={filmData.title} />
      <button className="rating">{filmData.rating}%</button>
      </div>
    </div>
  );
}