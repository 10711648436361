import React, { useState, useEffect } from "react";

const SearchBar = () => {
  const [searchInput, setSearchInput] = useState("");
  const [filmList, setFilmList] = useState([]);
  const [actorsList, setActorsList] = useState([]);
  const [originList, setOriginList] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [noResultsFilm, setNoResultsFilm] = useState(false);
  const [noResultsActors, setNoResultsActors] = useState(false);
  const [noResultsOrigin, setNoResultsOrigin] = useState(false);

  useEffect(() => {
    async function getFilmData() {
      const response = await fetch(
        "https://memeflix.tadeascerny.cz/php/filmy.php"
      );
      const data = await response.json();
      setFilmList(data);
    }

    async function getActorsData() {
      const response = await fetch(
        "https://memeflix.tadeascerny.cz/php/actors.php"
      );
      const data = await response.json();
      setActorsList(data);
    }

    async function getOriginData() {
      const response = await fetch(
        "https://memeflix.tadeascerny.cz/php/filmy.origins.php"
      );
      const data = await response.json();
      setOriginList(data);
    }

    getFilmData();
    getActorsData();
    getOriginData();
  }, []);

  const handleChange = (e) => {
    setSearchInput(e.target.value);
    e.target.value !== "" ? setShowResults(true) : setShowResults(false);
  };

  const filteredFilms = filmList.filter((film) => {
    return film.title.toLowerCase().includes(searchInput.toLowerCase());
  });

  const filteredOrigin = originList.filter((origin) => {
    return origin.m_title.toLowerCase().includes(searchInput.toLowerCase());
  });

  const filteredActors = actorsList.filter((actor) => {
    return (
      actor.a_name.toLowerCase().includes(searchInput.toLowerCase()) ||
      actor.a_family_name.toLowerCase().includes(searchInput.toLowerCase())
    );
  });

  useEffect(() => {
    setNoResultsFilm(filteredFilms.length === 0);
    setNoResultsActors(filteredActors.length === 0);
    setNoResultsOrigin(filteredOrigin.length === 0);
  }, [filteredFilms, filteredActors, filteredOrigin]);

  const handleFilmClick = (film) => {
    // Přesměrování na stránku s konkrétním filmem
    window.location.href = `/film/${film.film_url}`;

  };
  const handleActorClick = (actor) => {
    // Přesměrování na stránku s konkrétním filmem
    window.location.href = `/herec/${actor.a_url}`;

  };

  const handleOriginClick = (origin) => {
    // Přesměrování na stránku s konkrétním filmem
    window.location.href = `/memeflix-origin/${origin.m_film_url}`;

  };

  return (
    <div className="search-container">
      <input
        type="search"
        className="search-input"
        placeholder="Search here"
        onChange={handleChange}
        value={searchInput}
      />

      {showResults && (
        <>
          <table className="search-table">
            <thead>
              <tr>
                <th>Název filmu</th>
                <th>Hodnocení</th>
              </tr>
            </thead>
            <tbody>
              {noResultsFilm ? (
                <tr>
                  <td colSpan="1">
                    Omlouváme se, ale tento film ještě nemáme.
                  </td>
                  <td colSpan="1">:(</td>
                </tr>
              ) : (
                filteredFilms.slice(0, 3).map((film) => (
                  <tr key={film.id} onClick={() => handleFilmClick(film)}>
                    <td>{film.title}</td>
                    <td>{film.rating}%</td>
                  </tr>
                ))
              )}
            </tbody>

            <thead>
              <tr>
                <th>Název origin filmu</th>
                <th>Hodnocení</th>
              </tr>
            </thead>
            <tbody>
              {noResultsOrigin ? (
                <tr>
                  <td colSpan="1">
                    Omlouváme se, ale tento film ještě nemáme.
                  </td>
                  <td colSpan="1">:(</td>
                </tr>
              ) : (
                filteredOrigin.slice(0, 3).map((origin) => (
                  <tr key={origin.id} onClick={() => handleOriginClick(origin)}>
                    <td>{origin.m_title}</td>
                    <td>{origin.m_rating}%</td>
                  </tr>
                ))
              )}
            </tbody>

            <thead>
              <tr>
                <th>Jméno</th>
                <th>Příjmení</th>
              </tr>
            </thead>
            <tbody>
              {noResultsActors ? (
                <tr>
                  <td colSpan="1">
                    Omlouváme se, ale tato osoba nebyla nalezena.
                  </td>
                  <td colSpan="1">:(</td>
                </tr>
              ) : (
                filteredActors.slice(0, 3).map((actor) => (
                  <tr key={actor.id} onClick={() => handleActorClick(actor)}>
                    <td>{actor.a_name}</td>
                    <td>{actor.a_family_name}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
export default SearchBar;