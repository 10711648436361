import React, { useEffect, useState } from "react";

export function MemeflixOrigins() {
  const [filmDataList, setFilmDataList] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://memeflix.tadeascerny.cz/php/filmy.origins.php"
      );
      const data = await response.json();
      // Sort films alphabetically by title
      const sortedData = data.sort((a, b) => a.m_title.localeCompare(b.m_title));
      setFilmDataList(sortedData);
    }
    getData();
  }, []);

  return (
    <div className="reviewDiv">
      <h1 className="fade-in">Všechny parodie filmů A-Z</h1>
      <div className="filmos">
      {filmDataList.map((filmData) => (
        <FilmData key={filmData.m_id} filmData={filmData} />
      ))}
      </div>
    </div>
  );
}

function FilmData({ filmData }) {


  const handleClick = () => {
    // Přesměrování na stránku s konkrétním filmem
    window.location.href = `/memeflix-origin/${filmData.m_film_url}`;
   
  };

  return (
    <div className="filmyDiv" key={filmData.id} onClick={handleClick}>
      <div className="filminfo">
      <h3 className="filmdisc">        {
          (filmData.m_full_name.length > 25) ? `${filmData.m_full_name.slice(0, 25)}... ` : filmData.m_full_name 
        }</h3>
      <img className="fade-in" src={filmData.m_film_img} alt={filmData.m_title} />
      <button className="rating">{filmData.m_rating}%</button>
      </div>
    </div>
  );
}