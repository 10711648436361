import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import Header from "./Header";
import App from "./App";
import Footer from "./Footer";

import "./css/Desktop.css";
import "./css/Tablet.css";
import "./css/SmallTablet.css";
import "./css/Mobile.css";
import "./css/NotFound.module.css";

const Dialog = () => {
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    const hasShownDialog = sessionStorage.getItem("shownDialog");

    if (!hasShownDialog) {
      setShowDialog(true);
      sessionStorage.setItem("shownDialog", "true");
    }
  }, []);

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  return (
    <>
      {showDialog && (
        <div className="dialog-overlay">
          <div className="dialog-container">
            <dialog open={showDialog} className="cookies">
              <p>Souhlasíte s cookies?</p>
              <h6>
                Budou vám odstraněny orgány a prodány na černém trhu. (Myšleno
                ze srandy)
              </h6>
              <form method="dialog" onSubmit={handleDialogClose}>
                <button type="submit" onClick={send}>
                  Ano, souhlasím.
                </button>
                <button type="submit" onClick={send}>
                  Určitě souhlasím!
                </button>
              </form>
            </dialog>
          </div>
        </div>
      )}
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      <Dialog />
      <App />
      <Footer />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

async function send() {
  // Retrieve user's location
  const response = await fetch("https://ipapi.co/json/");
  const data = await response.json();
  const sIP = data.ip;
  const sLocation = data.country_name;
  const sCity = data.city;
  const sRegion = data.region;

  // Get current date and time
  const currentDate = new Date();
  const sDateTime = currentDate.toLocaleString();

  let requestData = {
    ip: sIP,
    location: sLocation,
    city: sCity,
    region: sRegion,
    datetime: sDateTime,
  };

  postData(
    "https://memeflix.tadeascerny.cz/php/add.php",
    requestData
  );
}

async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  response.text().then(function () {}); // parses JSON response into native JavaScript objects
}