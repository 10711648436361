import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NotFound } from "./NotFound";

export function MemeflixOrigin() {
  const { m_film_url } = useParams();
  const [filmData, setFilmData] = useState(null);
  const [showFullContent, setShowFullContent] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleClick = () => {
    setShowFullContent(!showFullContent);
  };

  const toggleFullscreen = (e) => {
    if (isFullscreen) {
      e.target.style.animation =
        "slide-out-blurred-top 0.5s cubic-bezier(0.755, 0.050, 0.855, 0.060)";
      setTimeout(function () {
        setIsFullscreen(!isFullscreen);
        e.target.style.animation = "restart 0.2s";
      }, 500);
    } else {
      setIsFullscreen(!isFullscreen);
    }
  };

  const shrinkFullscreenImage = (target) => {
    target.style.animation =
      "slide-out-blurred-top 0.5s cubic-bezier(0.755, 0.050, 0.855, 0.060)";
    setTimeout(function () {
      setIsFullscreen(false);
      target.style.animation = "restart 0.2s";
    }, 500);
  };

  useEffect(() => {
    async function getFilmData() {
      const response = await fetch(
        `https://memeflix.tadeascerny.cz/php/filmy.origins.php`
      );
      const data = await response.json();

      // Find the film with the matching film_url
      const foundFilm = data.find((film) => film.m_film_url === m_film_url);
      setFilmData(foundFilm);

      // Update the page title
      document.title = "MemeFlix - "+foundFilm.m_title || "Film Detail";
    }

    getFilmData();
  }, [m_film_url]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isFullscreen && !e.target.classList.contains("base")) {
        const imageElement = document.querySelector(".base");
        if (imageElement) {
          shrinkFullscreenImage(imageElement);
        }
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Escape") {
        const imageElement = document.querySelector(".base");
        if (imageElement) {
          shrinkFullscreenImage(imageElement);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isFullscreen]);

      // useEffect pro nastavení původního názvu stránky při opuštění detailu filmu
      useEffect(() => {
        return () => {
          document.title = "MemeFlix"; // Nahraďte skutečným původním názvem stránky
        };
      }, []);

  if (!filmData) {
    return <NotFound />;
  }

  return (
    <div className="reviewDiv">
      <h1 className="fade-in">{filmData.m_title}</h1>
      <div className="filmydiv">
        <div className="filminfo">
          <h3 className="filmdisc1">{filmData.m_full_name}</h3>
          <img
            src={filmData.m_film_img}
            alt={filmData.m_title}
            onClick={toggleFullscreen}
            className={isFullscreen ? "fullscreen-image base" : "base"}></img>
        </div>
        <div className="videof">
          <iframe
            className="video"
            title="Youtube player"
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={`https://youtube.com/embed/${filmData.youtubeID}?autoplay=0`}></iframe>
        </div>
        <p className="rating2">{filmData.m_rating} %</p>
        <p className="text123" onClick={handleClick}>
          {showFullContent ? (
            filmData.m_film_content
          ) : (
            <>
              {`${filmData.m_film_content.slice(0, 300)}... `}
              <span className="more-info">pro více informací klikněte ZDE</span>
            </>
          )}
        </p>
      </div>
    </div>
  );
}