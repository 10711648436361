import React from "react";

function Footer() {
  return (
    <footer>
      <h6>© 2022-2024 memeflix.tadeascerny.cz 2.IT</h6>
    </footer>
  );
}

export default Footer;
