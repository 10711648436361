export function About() {
  return (
    <div className="reviewDiv">
      <h1 className="fade-in">O nás</h1>
      <h4 className="onas">
        Jsme studenti druhého ročníku{" "}
        <a className="inspirace" href="https://spstrutnov.cz">
          Střední průmyslové školy Trutnov
        </a>{" "}
        a v rámci projektu WebDevelopment jsme vytvořili tento projekt s názvem
        MemeFlix. Jedná se o napodobeninu webových stránek{" "}
        <a href="https://csfd.cz" className="inspirace">
          ČSFD.CZ{" "}
        </a>
        a
        <a className="inspirace" href="https://kinobox.cz">
          KinoBox.CZ
        </a>
        . Projekt byl vyhotoven za pouhé 3 dny.
      </h4>
      <div className="aboutDiv">
        <h2 className="jmeno1">Tadeáš Černý</h2>
        <h3 className="obor">JavaScript a koordinace projektu</h3>
        <h4 className="popis">
          JavaScript je programovací jazyk pro webové stránky, který umožňuje
          vytvářet interaktivní prvky a reagovat na akce uživatele. Může měnit
          obsah stránky, pracovat s daty a komunikovat se serverem. Je široce
          používaný a umožňuje tvorbu různých typů webových aplikací.
        </h4>
        <h2 className="jmeno1">Marek Šubrt</h2>
        <h3 className="obor">CSS a grafika webu</h3>
        <h4 className="popis">
          CSS (Cascading Style Sheets) je jazyk pro formátování webových stránek
          a upravování jejich vzhledu. Pomocí CSS můžeš měnit barvy, písma,
          pozice a další vlastnosti různých prvků na stránce.
        </h4>
        <h2 className="jmeno1">David Kolavík</h2>
        <h3 className="obor">Databáze a PHP</h3>
        <h4 className="popis">
          Databáze je strukturovaný souborový systém pro ukládání a organizaci
          dat. PHP je jazyk pro skriptování webových stránek, který se často
          používá spolu s databázemi pro dynamické generování obsahu webových
          stránek.
        </h4>
        <h2 className="jmeno1">Radim Bastl</h2>
        <h3 className="obor">Grafika a úprava fotek pro MemeFlix Origins</h3>
        <h4 className="popis">
          Grafika je vytváření, úprava a prezentace vizuálních prvků. Photoshop
          je software pro práci s obrázky a grafikou.
        </h4>
      </div>
    </div>
  );
}
