import React, { useEffect, useState } from "react";

export function Videa() {
  const [filmDataList, setFilmDataList] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://memeflix.tadeascerny.cz/php/filmy.php"
      );
      const data = await response.json();
      // Sort films alphabetically by title
      const sortedData = data.sort((a, b) => a.title.localeCompare(b.title));
      setFilmDataList(sortedData);
    }
    getData();
  }, []);
  

  return (
    <div className="reviewDiv">
      <h1 className="fade-in">Všechny trailery A-Z</h1>
      <div className="videagrid">
      {filmDataList.map((filmData) => (
        <FilmData key={filmData.id} filmData={filmData} />
      ))}
      </div>
    </div>
  );
}

function FilmData({ filmData }) {


  const handleClick = () => {
    // Přesměrování na stránku s konkrétním filmem
    window.location.href = `/film/${filmData.film_url}`;
 
  };

  return (
    <div className="filmyDiv-videa"key={filmData.id} onClick={handleClick}>
      <div className="filminfo-videa">
      <button className="rating3">{filmData.full_name}</button>
      <div className="videov">
          <iframe
            className="video"
            title="Youtube player"
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={`https://youtube.com/embed/${filmData.youtubeID}?autoplay=0`}></iframe>
        </div>
      </div>
      </div>
  );
}